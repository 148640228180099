import Vue from 'vue'
import App from './App.vue'

//按需引入elementUI组件库
import {Button,Upload,Container,Header,Main,Footer,Menu,MenuItem,Dialog,Form,FormItem,Input,Message,Select,Link,Option} from 'element-ui';
//引入elementUI组件库
//import ElementUI from 'element-ui';
//引入elementUI样式
//import 'element-ui/lib/theme-chalk/index.css';
//引入路由插件
import VueRouter from 'vue-router'
//引入路由器
import router from './router'

//应用插件
Vue.use(Button)
Vue.use(Upload)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Link)
Vue.use(Option)
Vue.prototype.$message = Message;


//Vue.use(ElementUI)
Vue.use(VueRouter)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
