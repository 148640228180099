<template>
  <div id="app">
    <el-container>
      <el-header><MyHeader /></el-header>
      <el-container>
        <el-container>
          <el-main><router-view /></el-main>
          <el-footer> <MyFooter /></el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import MyHeader from "@/components/MyHeader";
import MyFooter from "@/components/MyFooter";
import axios from "axios";
export default {
  name: "App",
  components: {
    MyHeader,
    MyFooter,
  },
  //vue生命周期钩子函数
  beforeMount() {

    //若token为空则直接跳转到登陆页面
    if (localStorage.getItem("token") == null) {
      this.$router.push({ name: "denglu" });
    } else {
      //发送ajax请求验证token
      axios
        .get(
          "http://123.56.179.233:9898/photobed/userInfo/loginByToken?token=" +
            localStorage.getItem("token"),
        )
        .then(
          //请求成功的回调函数
          (response) => {
            console.log("请求后端成功", response.data);
            //判断返回的码值如果为200 则跳转页面 并保存成功的token
            if (response.data.code == 200) {
              this.$message.success("登陆成功");
              this.$router.push({ name: "shangchuan" });
            } else {
              this.$message.error("登陆失败: " + response.data.message);
              this.$router.push({ name: "denglu" });
            }
          },
          (error) => {
            this.$message.error(error.message);
            this.$router.push({ name: "denglu" });
          }
        )
        .catch //请求失败的回调函数
        ()
        .finally //回调函数
        ();
    }
  },
};
</script>

<style>
/* 导航栏容器样式 */
.el-header {
  background-color: #4ea5db;
  color: #333;
  text-align: center;
  line-height: 60px;
}

/* 右侧导航栏样式 */
.el-aside {
  background-color: #fbdbb4;
  color: #333;
  text-align: center;
  line-height: 200px;
}

/* 内容区容器样式 */
.el-main {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  /* line-height: 160px; */
}
/* 页尾容器样式 */
.el-footer {
  background-color: #258a57;
  color: #333;
  text-align: center;
  line-height: 60px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

body {
  font-family: "Helvetica Neue";
}
</style>