<template>
  <div>上传视频页面</div>
</template>

<script>
export default {
      name: "UploadVideo",

}
</script>

<style>

</style>