//路由配置
import VueRouter from 'vue-router'
import UserLogin from '../pages/UserLogin'
import UploadPhoto from '../pages/UploadPhoto'
import UploadVideo from '../pages/UploadVideo'
import axios from "axios";
import {Message} from 'element-ui';


//创建并暴露一个路由器
const router = new VueRouter({
    routes: [
        { //登录页面
            name: 'denglu',
            path: '/UserLogin',
            component: UserLogin,
            //路由原信息
            meta: {
                isAuth: false,
                title: "用户登陆"
            }
        },
        { //上传图片
            name: 'shangchuan',
            path: '/UploadPhoto',
            component: UploadPhoto,
            //路由原信息
            meta: {
                isAuth: true, //是否需要鉴权
                title: "照片上传" //页面标题
            }
        },
        { //上传视频
            name: 'UploadVideo',
            path: '/UploadVideo',
            component: UploadVideo,
            //路由原信息
            meta: {
                isAuth: false,
                title: "视频上传"
            }
        }
    ]
})

//配置全局路由守卫
router.beforeEach((to, from, next) => {
    //判断跳转项是否需要鉴权
    if (to.meta.isAuth) {
        //先判断localStorage中是否有token
        if (localStorage.getItem("token") == null) {
            //若token为空并且当前不在登陆页 则直接跳转到登陆页面
            if (router.currentRoute.path != "/UserLogin") {
                router.push("/UserLogin")
            }
            //发送ajax请求验证token
        } else {
            axios
                .get(
                    "http://123.56.179.233:9898/photobed/userInfo/loginByToken?token=" +
                    localStorage.getItem("token"),
                )
                .then(
                    //请求成功的回调函数
                    (response) => {
                        //判断返回的码值如果为200 则跳转页面 并保存成功的token
                        if (response.data.code == 200) {
                            console.log("权限校验通过 放行", response.data);
                            next()
                        } else {
                            Message.error("权限校验未通过,请先登陆")
                            if (router.currentRoute.path != "/UserLogin") {
                                router.push("/UserLogin")
                            }
                        }
                    },
                    (error) => {
                        console.log("请求失败: " + error);
                        Message.error("网络异常")
                        if (router.currentRoute.path != "/UserLogin") {
                            router.push("/UserLogin")
                        }
                    }
                )
                .catch //请求失败的回调函数
                ()
                .finally //回调函数
                ();
        }
    } else {
        next()
    }
})

//全局后置路由守卫  初始化时执行,每次路由切换后执行
router.afterEach((to) => {
    if (to.meta.title) {
        //标题默认读取项目名称  修改index.html中的title达到一致
        document.title = to.meta.title //修改网页的title
    } else {
        document.title = '尘埃图床'
    }
})



export default router