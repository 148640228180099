<template>
  <div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#4ea5db"
      text-color="#fff"
      active-text-color="ffd04b"
    >
      <span>尘埃图床</span>
      <el-menu-item index="1" @click="userLogin" ref="userLogin">登录</el-menu-item>
      <el-menu-item index="2" @click="UploadPhoto">照片上传</el-menu-item>
      <el-menu-item index="3" @click="UploadVideo">视频上传</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "MyHeader",
  data() {
    return {
      activeIndex: "1",
    };
  },
  methods: {
    //点击登陆跳转
    userLogin() {
      //先判断是否已在当前页面
      if (this.$route.path != "/UserLogin") {
        this.$router.push({
          name: "denglu",
        });
      } else {
        this.$message.warning("已在当前页面");
      }
    },
    UploadPhoto() {
      if (this.$route.path != "/UploadPhoto") {
        this.$router.push({
          name: "shangchuan",
        });
      } else {
        this.$message.warning("已在当前页面");
      }
    },
    UploadVideo() {
      if (this.$route.path != "/UploadVideo") {
        this.$router.push({
          name: "UploadVideo",
        });
      } else {
        this.$message.warning("已在当前页面");
      }
    },
  },
};
</script>

<style scoped>
/* 导航菜单项居左 */
.el-menu--horizontal > .el-menu-item {
  float: right;
}
/* 导航菜单项居左 */
.el-menu--horizontal > .el-submenu {
  float: right;
}
</style>