<template>
  <div>
    <h1>欢迎登陆</h1>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="用户名">
        <el-input v-model="form.UserName" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input
          v-model="form.Password"
          show-password
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit(form)">立即登陆</el-button>
        <el-button>取消</el-button>
        <!-- <el-button>注册</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "UserLogin",
  data() {
    return {
      form: {
        UserName: "",
        Password: "",
      },
    };
  },
  methods: {
    //表单登陆事件
    onSubmit(form) {
      axios
        .post("http://123.56.179.233:9898/photobed/userInfo/login", {
          UserName: form.UserName,
          Password: form.Password,
        })
        .then(
          //请求成功的回调函数
          (response) => {
            console.log("请求后端成功", response.data);
            //判断返回的码值如果为200 则跳转页面 并保存成功的token
            if (response.data.code == 200) {
              this.$message.success("登陆成功");
              //将token存入localStorage中,并跳转到上传页面
              localStorage.setItem("token", response.data.data);
              this.$router.push({ name: "shangchuan" });
            } else {
              this.$message.error("登陆失败: " + response.data.message);
            }
          },
          (error) => {
            console.log("请求后端失败", error.message);
            this.$message.error("请求服失败,请检查网络设置");
          }
        )
        .catch //请求失败的回调函数
        ()
        .finally //回调函数
        ();
    },
  },
};
</script>

<style>
</style>