<template>
  <div>
    存储位置 &nbsp;
    <el-select v-model="region" placeholder="请选择上传空间">
      <el-option label="华南 zhaoyongbin" value="zhaoyongbin"></el-option>
      <el-option label="华南 zlovej" value="zlovej"></el-option>
    </el-select>
    <br />
    <br />
    <el-upload
      class="upload-demo"
      drag
      multiple
      action="https://upload-z2.qiniup.com"
      :data="form"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-error="handError"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
    </el-upload>
    <br />
    <br />
    <el-link :href="this.url" type="primary">{{ this.url }}</el-link>

    <br />
    <br />
    <el-button
      type="primary"
      class="copy_btn"
      :data-clipboard-text="this.url"
      @click="copy"
    >
      复制链接
    </el-button>
  </div>
</template>


<script>
import axios from "axios";
import Clipboard from "clipboard";
export default {
  name: "UploadPhoto",
  data() {
    return {
      form: {
        token: "",
        key: "",
      },
      region: "zhaoyongbin",
      url: "",
    };
  },
  methods: {
    async beforeUpload() {
      //先通过axios获取上传token
      await axios
        .get("http://123.56.179.233:9898/photobed/uploadPhoto/getAuthOrKeys?region=" + this.region)
        .then(
          //请求成功的回调函数
          (response) => {
            console.log("后端请求成功", response.data);
            this.form.token = response.data.token;
            this.form.key = response.data.key;
            this.url = response.data.url;
          },
          (error) => {
            console.log("后端请求失败", error.message);
          }
        )
        .catch() //请求失败的回调函数
        .finally(); //回调函数
    },
    handleSuccess(response, file) {
      console.log("上传成功了" + JSON.stringify(response));
      console.log(file);
      this.form.key = response.key;
    },
    handError(err) {
      console.log(err);
      console.log(
        "上传失败   token=" + this.form.token + "  key=" + this.form.key
      );
    },
    copy() {
      var clipboard = new Clipboard(".copy_btn");
      clipboard.on("success", () => {
        // 释放内存
        clipboard.destroy();
        this.$message({
          message: "复制成功了",
          type: "success",
        });
      });

      clipboard.on("error", () => {
        clipboard.destroy();
        this.$message({
          message: "复制失败",
          type: "warning",
        });
      });
    },
  },
};
</script>