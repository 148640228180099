<template>
  <div id="footer">
   2021-2022@尘埃啊. 鲁ICP备2021014813号-1
  </div>
</template>

<script>

export default {
  name: 'MyFooter',
  components: {
  
  }
}
</script>

<style scoped>

</style>